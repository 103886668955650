import React, { Component } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Box,
  Snackbar,
} from "@mui/material";
class Emails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: null,
      snackbar: false,
      message: "",
      isRejected: false,
      isInAllowList: false,
    };
  }

  async componentDidMount() {
    this.getUserMails();
    this.checkEmailForAlowList();
    this.checkEmailForRejectedList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      this.getUserMails();
      this.checkEmailForAlowList();
      this.checkEmailForRejectedList();
    }
  }

  getUserMails = async () => {
    if (!this.props.email) {
      return;
    }
    try {
      const response = await fetch(
        `https://mandrillapp.com/api/1.0/messages/search?key=yBlfglplVDWpymvp6qX-Ew&query=${this.props.email}`
      );
      const mails = await response.json();
      this.setState({ search: mails });
    } catch (error) {
      console.error(error);
    }
  };

  moveToAllowList = async () => {
    const removeFromReject = await fetch(
      `https://mandrillapp.com/api/1.0/rejects/delete?key=yBlfglplVDWpymvp6qX-Ew&email=${this.props.email}`
    );
    const removed = await removeFromReject.json();

    if (removed.deleted) {
      const addToAllowList = await fetch(
        `https://mandrillapp.com/api/1.0/allowlists/add?key=yBlfglplVDWpymvp6qX-Ew&email=${this.props.email}&comment=From WebTool`
      );
      const allowList = await addToAllowList.json();

      allowList.added &&
        this.setState({
          snackbar: true,
          message: "This email moved to allow list",
          isRejected: false,
        });
    }
    this.checkEmailForAlowList();
  };

  checkEmailForAlowList = async () => {
    if (!this.props.email) {
      return;
    }
    const removeFromReject = await fetch(
      `https://mandrillapp.com/api/1.0/allowlists/list?key=yBlfglplVDWpymvp6qX-Ew&email=${this.props.email}`
    );
    const alowList = await removeFromReject.json();
    if (alowList.length) {
      this.setState({ isInAllowList: true });
    } else {
      this.setState({ isInAllowList: false });
    }
  };

  removeFromAllowList = async () => {
    const removeFromAllowList = await fetch(
      `https://mandrillapp.com/api/1.0/allowlists/delete?key=yBlfglplVDWpymvp6qX-Ew&email=${this.props.email}`
    );
    const removed = await removeFromAllowList.json();

    removed.deleted &&
      this.setState({
        snackbar: true,
        message: "This email removed from allow list",
      });
      
    this.checkEmailForAlowList();
  };

  checkEmailForRejectedList = async () => {
    if (!this.props.email) {
      return;
    }
    const removeFromReject = await fetch(
      `https://mandrillapp.com/api/1.0/rejects/list?key=yBlfglplVDWpymvp6qX-Ew&email=${this.props.email}`
    );
    const rejected = await removeFromReject.json();

    if (rejected.length) {
      this.setState({ isRejected: true });
    } else {
      this.setState({ isRejected: false });
    }
  };

  removeFromRejectList = async () => {
    const removeFromReject = await fetch(
      `https://mandrillapp.com/api/1.0/rejects/delete?key=yBlfglplVDWpymvp6qX-Ew&email=${this.props.email}`
    );
    const removed = await removeFromReject.json();
    if (removed.deleted) {
      this.setState({
        isRejected: false,
      });
    }
    this.checkEmailForRejectedList();
  };

  handleClose = () => {
    this.setState({ snackbar: false });
  };

  render() {
    return (
      <div className="emails">
        {
          <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
            {
              <>
		    { /* <Button variant="contained" onClick={this.removeFromRejectList} sx={{mr: '25px'}}>
                  Remove email from Reject List
                </Button> */}
                {!this.state.isInAllowList ? (
                  <Button variant="contained" onClick={this.moveToAllowList}>
                    Move email to Allow List
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={this.removeFromAllowList}
                  >
                    Remove email from Allow List
                  </Button>
                )}
              </>
            }
          </Box>
        }
        {this.state.search &&
          this.state.search.map((mail) => (
            <Accordion>
              <AccordionSummary>
                {mail.subject} ({mail.state})
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Email: {mail.email}</Typography>
                <Typography>State: {mail.state}</Typography>
                <Typography>
                  Bounce description: {mail.bounce_description}
                </Typography>
                {mail.reject && (
                  <>
                    <Typography>Reject reason: {mail.reject.reason}</Typography>
                    <Typography>
                      Last event at: {mail.reject.last_event_at}
                    </Typography>
                  </>
                )}
                <Typography>Sender: {mail.sender}</Typography>
                <Typography>Subject: {mail.subject}</Typography>
                <Typography>Opens: {mail.opens}</Typography>
                <Typography>Clicks: {mail.clicks}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        <Snackbar
          open={this.state.snackbar}
          autoHideDuration={6000}
          message={this.state.message}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}
export default Emails;
