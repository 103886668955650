import React, { Component } from "react";
import "../Styles/Search.css";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { Tabs, Tab } from "react-bootstrap";
import { getTokenSilently } from "../react-auth0-wrapper";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: "",
      dropdownVal: "pfxUserId",
      redirect: false,
      showError: false,
      errorText: "",
    };
  }

  handleInputChange = (event) => {
    this.setState({ searchVal: event.target.value });
  };

  handleDropdownChange = (event) => {
    this.setState({ dropdownVal: event.target.value });
  };

  onSubmitClick = () => {
    this.props.getSearchData(this.state.searchVal, this.state.dropdownVal);
  };

  onMigrateClick = async () => {
    const token = await getTokenSilently();

    const response = await fetch(
      `https://member-services.${process.env.REACT_APP_AUTH0_API}/admin/account/migrate`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email: this.state.searchVal }),
      }
    );
    console.log(response);

    if (response.status >= 300) {
      this.setState({ showError: true });
      if (response.status === 404)
        this.setState({ errorText: "The given email was not found" });
      return;
    }
    const res = await response.json();
    console.log(res.pfxUserId);
    this.setState({ searchVal: res.pfxUserId });
    this.setState({ dropdownVal: "pfxUserId" });
    this.setState({ redirect: true });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: "/results/user",
            search: `?id=${this.state.searchVal}`,
          }}
        />
      );
    }

    let submit = (
      <Link
        className="link"
        to={{
          pathname: "/results/user",
          search: `?id=${this.state.searchVal}`,
        }}
      >
        <button className="submit" onClick={this.onSubmitClick}>
          Enter
        </button>
      </Link>
    );
    if (this.state.dropdownVal === "barcode") {
      submit = (
        <Link
          className="link"
          to={{
            pathname: "/results/user",
            search: `?barcode=${this.state.searchVal}`,
          }}
        >
          <button className="submit" onClick={this.onSubmitClick}>
            Enter
          </button>
        </Link>
      );
    }
    return (
      <div className="App">
        <div className="welcome">Welcome back!</div>
        <Tabs defaultActiveKey="findUser" className="tabs">
          <Tab eventKey="findUser" title="Find User">
            <div className="lookup">
              <div className="searchBy">
                <div>Search by...</div>
                <select
                  value={this.state.dropdownVal}
                  onChange={this.handleDropdownChange}
                >
                  <option value="pfxUserId">PFX User ID</option>
                  <option value="barcode">Barcode</option>
                </select>
              </div>
              <input
                className="textInput"
                placeholder="Begin typing..."
                onChange={(event) => this.handleInputChange(event)}
              />
              {submit}
            </div>
          </Tab>
          <Tab eventKey="migrateUser" title="Migrate User">
            <div className="migrateUser">
              <div>
                <input
                  className="textInput"
                  placeholder="Begin typing..."
                  onChange={(event) => this.handleInputChange(event)}
                />
                <button className="submit" onClick={this.onMigrateClick}>
                  Enter
                </button>
              </div>
              {this.state.showError && (
                <div>
                  <label className="errorText">{this.state.errorText}</label>
                </div>
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default Search;
