import React, { useState } from "react";
import { Button } from "reactstrap";
import { useAuth0 } from "../react-auth0-wrapper";

const ExternalApi = () => {
  const [showResult, setShowResult] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const { getTokenSilently } = useAuth0();

  const callApi = async () => {
    try {
      const token = await getTokenSilently();
      

      const response1 = await fetch("https://member-services.nonprod.pfx-nonprod.com/admin/user-details/pfx:users:65a8984a-b1fd-11e8-9010-cb020ebc280e", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const responseData1 = await response1.json();
      console.log(responseData1);

      const response = await fetch("https://membership.nonprod.pfx-nonprod.com/admin/memberships/lookup?abcBarcode=23DFB294A8AE4472A0D2E90E19538B6F", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      const responseData = await response.json();
      console.log(responseData);
      setShowResult(true);
      setApiMessage(responseData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="mb-5">
        <h1>External API</h1>
        <p>
          Ping an external API by clicking the button below. This will call the
          external API using an access token, and the API will validate it using
          the API's audience value.
        </p>
        <Button color="primary" className="mt-5" onClick={callApi}>
          Ping API
        </Button>
      </div>
      <div className="result-block-container">
        <div className={`result-block ${showResult && "show"}`}>
          <h6 className="muted">Result</h6>
            {JSON.stringify(apiMessage, null, 2)}
        </div>
      </div>
    </>
  );
};
export default ExternalApi;