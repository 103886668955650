import React, {Component} from 'react';
import MUIDataTable from "mui-datatables";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTokenSilently } from "../react-auth0-wrapper";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class Crashes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            analyticsData: null,
            OSToSearch: 'iOS',
            iOSAppcenterAppName: null,
            androidAppcenterAppName: null,
            appcenterOwnerName: null
        };

        this.onRowClick = this.onRowClick.bind(this);
    }

    async componentDidMount() {
        if (!this.props.pfxUserId) {
            return;
        }
        
        this.updateComponentData();
    }

    handleSelectChange(event) {
        if (this.state.OSToSearch !== event.target.value) {
            this.setState({ OSToSearch: event.target.value, analyticsData: null }, this.updateComponentData);
        }
    }

    async updateComponentData() {
        const token = await getTokenSilently();
        
        this.fetchAndUpdateAppcenterUrls(token);
        this.fetchAndUpdateCrashData(token);
    }

    async fetchAndUpdateAppcenterUrls(token) {
        try {
            const response = await fetch(`/api/appcenterConfig`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const json = await response.json();
    
            this.setState({
                androidAppcenterAppName: json.data.androidAppcenterAppName, 
                iOSAppcenterAppName: json.data.iOSAppcenterAppName,
                appcenterOwnerName: json.data.appcenterOwnerName
            });
        } catch(error) {
            console.error(error);
        }
    }

    async fetchAndUpdateCrashData(token) {
        try {
            const response = await fetch(`/api/${this.state.OSToSearch}/crashes?pfxUserId=${this.props.pfxUserId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }    
            });

            const json = await response.json();
    
            const columns = [
                'appBuild', 
                'appVersion', 
                'codeRaw', 
                'count', 
                'deviceCount', 
                'errorGroupId', 
                'errorType', 
                'exceptionAppCode', 
                'exceptionClassMethod',
                'exceptionClassName',
                'exceptionMessage',
                'exceptionMethod',
                'exceptionType',
                'firstOccurrence',
                'lastOccurrence',
                'matchingReportsCount',
                'state'
            ];
    
            const data = json.data.errorGroups;
    
            data.map(group => {
                if (group['exceptionAppCode']) {
                    group['exceptionAppCode'] = group['exceptionAppCode'].toString();
                }

                if (group['exceptionClassMethod']) {
                    group['exceptionClassMethod'] = group['exceptionClassMethod'].toString();
                }
            });
    
            this.setState({ analyticsData: { columns, data }});
        } catch (error) {
            console.error(error);
        }
    }

    onRowClick(event) {
        if (this.state.OSToSearch === 'Android' && this.state.androidAppcenterAppName && this.state.appcenterOwnerName) {
            window.open(`https://appcenter.ms/orgs/${this.state.appcenterOwnerName}/apps/${this.state.androidAppcenterAppName}/crashes/errors/${event[5]}/overview`)
        } else if (this.state.OSToSearch === 'iOS' && this.state.iOSAppcenterAppName && this.state.appcenterOwnerName) {
            window.open(`https://appcenter.ms/orgs/${this.state.appcenterOwnerName}/apps/${this.state.iOSAppcenterAppName}/crashes/errors/${event[5]}/overview`)
        }
    }
    
    render(){
        const tableOptions = {
            selectableRows: "none",
            onRowClick: this.onRowClick,
            print: false
        };

        return (
            <div>
                <div className="selectControl">
                {
                    <FormControl style={{minWidth: '120px'}}>
                        <InputLabel>Phone OS</InputLabel>
                        <Select
                        value={this.state.OSToSearch}
                        onChange={this.handleSelectChange.bind(this)}>
                            <MenuItem value={'iOS'}>iOS</MenuItem>
                            <MenuItem value={'Android'}>Android</MenuItem>
                        </Select>
                    </FormControl>
                }
                </div>
                <div className="analytics" >
                    <div>
                    {
                        !this.props.pfxUserId && 
                        <error>Invalid user provided</error>
                    }
                    {
                        this.state.OSToSearch && 
                        !this.state.analyticsData &&
                        <div style={{textAlign: 'center'}}><CircularProgress size={80} /></div>
                    }
                    {
                        this.state.OSToSearch && 
                        this.state.analyticsData &&
                        <MUIDataTable
                            title={`Crashes for ${this.state.OSToSearch} user ${this.props.pfxUserId} `}
                            data={this.state.analyticsData.data}
                            columns={this.state.analyticsData.columns}
                            options={tableOptions}
                            />
                    }
                    </div>
                </div>
            </div>
        );
    }
};

export default Crashes;