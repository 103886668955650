import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./Components/PrivateRoute";
import Search from './Components/Search';
import Results from './Components/Results';
import AuthProfile from "./Components/AuthProfile";
import ExternalApi from "./Components/ExternalApi";
import { useAuth0 } from "./react-auth0-wrapper";
import './Styles/MainApp.css';
import 'typeface-archivo-narrow';
const MainApp = () => {
  const { loading, logout } = useAuth0();

  const state = {
    search: '',
    dropdown: ''
  };    

  if (loading) {
    return <div />;
  }

  const searchDataCallback = (searchVal, dropdownVal) => {
    state.search = searchVal;
    state.dropdown = dropdownVal;
  }

  return (
    <div>
      <div className="logout">
        <img src="https://www.planetfitness.com/sites/default/files/logo.png" alt="logo" className="logo" />
        <button className="logoutButton" onClick={() => logout({returnTo: process.env.REACT_APP_LOGOUT})}>Log Out</button>
      </div>
      
      <Router>
        <Switch>
          <PrivateRoute exact path="/" render={(props) => <Search {...props} getSearchData={searchDataCallback} />} />
          <PrivateRoute path="/auth0-delegated-admin/login" render={(props) => <Search {...props} getSearchData={searchDataCallback} />} />
          <PrivateRoute path="/external-api" component={ExternalApi} />
          <PrivateRoute path={`/results`} render={(props) => <Results {...props} dropdownVal={state.dropdown} searchVal={state.search} />} />
        </Switch>
      </Router>
    </div>
    
  );
};

export default MainApp;