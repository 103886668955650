import React, { Component } from 'react';

class DigitalMembershipCell extends Component {
    constructor(props) {
        debugger
        super(props);
    }

    render() {
        return (
            <div className="membership">
                <div>
                    <div className="membershipInfo">
                        <p className="paramName">PFX User ID:</p>
                        <p className="info">{this.props.membership.pfxUserId}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">PFX Membership ID:</p>
                        <p className="info">{this.props.membership.pfxMembershipId}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">Vendor Type:</p>
                        <p className="info">{this.props.membership.vendorType}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">Original Transaction Id:</p>
                        <p className="info">{this.props.membership.originalTransactionId}</p>
                    </div>

                    <div className="membershipInfo">
                        <p className="paramName">Last Transaction Id:</p>
                        <p className="info">{this.props.membership.lastTransactionId}</p>
                    </div>

                    <div className="membershipInfo">
                        <p className="paramName">Bundle Id:</p>
                        <p className="info">{this.props.membership.bundleId}</p>
                    </div>

                    <div className="membershipInfo">
                        <p className="paramName">Subscription Id:</p>
                        <p className="info">{this.props.membership.subscriptionId}</p>
                    </div>

                    <div className="membershipInfo">
                        <p className="paramName">Membership Status:</p>
                        <p className="info">{this.props.membership.membershipStatus}</p>
                    </div>

                    <div className="membershipInfo">
                        <p className="paramName">Start Date:</p>
                        <p className="info">{this.props.membership.startDate}</p>
                    </div>

                    <div className="membershipInfo">
                        <p className="paramName">End Date:</p>
                        <p className="info">{this.props.membership.endDate}</p>
                    </div>

                    <div className="membershipInfo">
                        <p className="paramName">Renewal Date:</p>
                        <p className="info">{this.props.membership.renewalDate}</p>
                    </div>

                </div>
                <hr></hr>
            </div>

        );
    }

};
export default DigitalMembershipCell;
