import React, { Component } from 'react';

class MembershipCell extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let activeText, catText, sponsorTypeText = "";
        if (this.props.isActive)
            activeText = "Active";
        else
            activeText = "Inactive";

        switch (this.props.planCat) {
            case "BC":
                catText = "Black Card";
                break;
            case "GC":
                catText = "Gold Card";
                break;
            case "PREPAY":
                catText = "Pre-pay";
                break;
            case "MONTHLY":
                catText = "Monthly";
                break;
            case "UNKNOWN":
                catText = "Unknown";
                break;
        }

        switch (this.props.sponsorType) {
            case "individual":
                sponsorTypeText = "Individual";
                break;
            case "corporate":
                sponsorTypeText = "Corporate";
                break;
        }

        return (
            <div className="membership">
                <div>
                    <div className="membershipInfo">
                        <p className="paramName">PFX User ID:</p>
                        <p className="info">{this.props.pfxUserId}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">PFX Club ID:</p>
                        <p className="info">{this.props.pfxClubId}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">Plan Category:</p>
                        <p className="info">{catText}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">ABC Member ID:</p>
                        <p className="info">{this.props.abcId}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">ABC Club Number:</p>
                        <p className="info">{this.props.abcClubNum}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">ABC Barcode:</p>
                        <p className="info">{this.props.abcBarcode}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">Is Active:</p>
                        <p className="info">{activeText}</p>
                    </div>
                    <div className="membershipInfo">
                        <p className="paramName">Sponsor Type:</p>
                        <p className="info">{sponsorTypeText}</p>
                    </div>
                </div>
                <hr></hr>
            </div>

        );
    }

};
export default MembershipCell;
