import React, { Component } from "react";
import {
  Switch,
  Route,
  NavLink,
  Link,
  BrowserRouter as Router,
} from "react-router-dom";
import User from "./User";
import Membership from "./Membership";
import DigitalMembership from "./DigitalMembership";
import Profile from "./Profile";
import Emails from "./Emails";
import Events from "./Events";
import Exceptions from "./Exceptions";
import Crashes from "./Crashes";
import "../Styles/Results.css";
import { FaChevronLeft } from "react-icons/fa";
import { useAuth0, Auth0Context } from "../react-auth0-wrapper";
import { getTokenSilently } from "../react-auth0-wrapper";
import queryString from "query-string";

class Results extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    apiMessage: [],
    pfxID: "",
  };

  async componentDidMount() {
    try {
      //const { getTokenSilently } = await useAuth0();
      const token = await getTokenSilently();

      var barcode;
      var pfxUserId = queryString.parse(this.props.location.search).id;
      if (pfxUserId === undefined) {
        barcode = queryString.parse(this.props.location.search).barcode;
        console.log(barcode);
        const barResp = await fetch(
          `https://membership.${process.env.REACT_APP_AUTH0_API}/admin/memberships/lookup?abcBarcode=${barcode}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Cache-Control": "no-store",
            },
          }
        );

        const bar = await barResp.json();
        console.log(bar);
        pfxUserId = bar.memberships[0].pfxUserId;
      }

      this.setState({ pfxID: pfxUserId });

      const response = await fetch(
        `https://member-services.${process.env.REACT_APP_AUTH0_API}/admin/user-details/${pfxUserId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Cache-Control": "no-store",
          },
        }
      );

      const responseData = await response.json();
      console.log(responseData);
      this.setState({ apiMessage: responseData });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    let user = (
      <NavLink
        className="link"
        to={{ pathname: "/results/user", search: `?id=${this.state.pfxID}` }}
        activeClassName="activeRoute"
      >
        User
      </NavLink>
    );
    let membership = (
      <NavLink
        className="link"
        to={{
          pathname: "/results/membership",
          search: `?id=${this.state.pfxID}`,
        }}
        activeClassName="activeRoute"
      >
        Membership
      </NavLink>
    );
    let digitalMembership = (
      <NavLink
        className="link"
        to={{
          pathname: "/results/digital-membership",
          search: `?id=${this.state.pfxID}`,
        }}
        activeClassName="activeRoute"
      >
        Digital Membership
      </NavLink>
    );
    let profile = (
      <NavLink
        className="link"
        to={{ pathname: "/results/profile", search: `?id=${this.state.pfxID}` }}
        activeClassName="activeRoute"
      >
        Profile
      </NavLink>
    );
    let emails = (
      <NavLink
        className="link"
        to={{ pathname: "/results/emails", search: `?id=${this.state.pfxID}` }}
        activeClassName="activeRoute"
      >
        Emails
      </NavLink>
    );
    let events = (
      <NavLink
        className="link"
        to={{ pathname: "/results/events", search: `?id=${this.state.pfxID}` }}
        activeClassName="activeRoute"
      >
        Events
      </NavLink>
    );
    let exceptions = (
      <NavLink
        className="link"
        to={{
          pathname: "/results/exceptions",
          search: `?id=${this.state.pfxID}`,
        }}
        activeClassName="activeRoute"
      >
        Exceptions
      </NavLink>
    );
    let crashes = (
      <NavLink
        className="link"
        to={{ pathname: "/results/crashes", search: `?id=${this.state.pfxID}` }}
        activeClassName="activeRoute"
      >
        Crashes
      </NavLink>
    );

    let pfxUserId,
      email,
      givenName,
      familyName,
      gender,
      phone,
      address,
      birthDate = "";
    if (this.state.apiMessage.user !== undefined) {
      pfxUserId = this.state.apiMessage.user.pfxUserId;
      familyName = this.state.apiMessage.user.familyName;
      givenName = this.state.apiMessage.user.givenName;
      email = this.state.apiMessage.user.email;

      if (this.state.apiMessage.user.memberDetails != undefined) {
        address = this.getAddress(
          this.state.apiMessage.user.memberDetails.address
        );
        birthDate =
          this.state.apiMessage.user.memberDetails.birthDate != null
            ? this.state.apiMessage.user.memberDetails.birthDate
            : "";
        gender =
          this.state.apiMessage.user.memberDetails.gender != null
            ? this.state.apiMessage.user.memberDetails.gender
            : "";
        phone =
          this.state.apiMessage.user.memberDetails.telephone != null
            ? this.state.apiMessage.user.memberDetails.telephone
            : "";
      }
    } else {
      givenName = "User Not";
      familyName = "Found";
    }
    let name = givenName + " " + familyName;

    let memberships = [];
    let digitalMemberships = [];

    if (this.state.apiMessage.memberships !== undefined)
      memberships = this.state.apiMessage.memberships;

    if (this.state.apiMessage.digitalMemberships !== undefined)
      digitalMemberships = this.state.apiMessage.digitalMemberships;

    let height,
      weight,
      heightVal,
      weightVal,
      heightUnit,
      weightUnit,
      fitnessGoal,
      expLevel = "";
    if (
      this.state.apiMessage.fitnessProfile
    ) {
      height =
        this.state.apiMessage.fitnessProfile.height
          ? this.state.apiMessage.fitnessProfile.height
          : null;
      weight =
        this.state.apiMessage.fitnessProfile.weight
          ? this.state.apiMessage.fitnessProfile.weight
          : null;
      heightVal =
        this.state.apiMessage.fitnessProfile.height
          ? this.state.apiMessage.fitnessProfile.height.value
          : 0;
      weightVal =
        this.state.apiMessage.fitnessProfile.weight
          ? this.state.apiMessage.fitnessProfile.weight.value
          : 0;
      heightUnit =
        this.state.apiMessage.fitnessProfile.height
          ? this.state.apiMessage.fitnessProfile.height.unit
          : "INCHES";
      weightUnit =
        this.state.apiMessage.fitnessProfile.weight
          ? this.state.apiMessage.fitnessProfile.weight.unit
          : "POUNDS";
      fitnessGoal =
        this.state.apiMessage.fitnessProfile.fitnessGoal
          ? this.state.apiMessage.fitnessProfile.fitnessGoal
          : "STAY_ACTIVE";
      expLevel =
        this.state.apiMessage.fitnessProfile.experienceLevel
          ? this.state.apiMessage.fitnessProfile.experienceLevel
          : "BEGINNER";
    }
    return (
      <div>
        <br />
        <Link to="/" className="searchLink">
          <FaChevronLeft className="arrow" />
          Search
        </Link>

        <div className="userName">{name}</div>

        <Router>
          <div className="tabs">
            <div className="results">
              {user}
              {membership}
              {digitalMembership}
              {profile}
              {emails}
            </div>

            <Switch>
              <Route
                exact
                path="/results/user"
                render={(props) => (
                  <User
                    {...props}
                    pfxUserId={pfxUserId}
                    email={email}
                    givenName={givenName}
                    familyName={familyName}
                    gender={gender}
                    phone={phone}
                    address={address}
                    birthDate={birthDate}
                  />
                )}
              />
              <Route
                exact
                path="/results/membership"
                render={(props) => (
                  <Membership
                    {...props}
                    memberships={memberships}
                    pfxUserId={pfxUserId}
                  />
                )}
              />
              <Route
                exact
                path="/results/digital-membership"
                render={(props) => (
                  <DigitalMembership
                    {...props}
                    digitalMemberships={digitalMemberships}
                    pfxUserId={pfxUserId}
                  />
                )}
              />
              <Route
                exact
                path="/results/profile"
                render={(props) => (
                  <Profile
                    {...props}
                    height={height}
                    weight={weight}
                    heightVal={heightVal}
                    weightVal={weightVal}
                    heightUnit={heightUnit}
                    weightUnit={weightUnit}
                    fitnessGoal={fitnessGoal}
                    expLevel={expLevel}
                    pfxUserId={pfxUserId}
                  />
                )}
              />
              <Route
                exact
                path="/results/emails"
                render={(props) => (
                  <Emails {...props} pfxUserId={pfxUserId} email={email} />
                )}
              />
              <Route
                exact
                path="/results/events"
                render={(props) => <Events {...props} pfxUserId={pfxUserId} />}
              />
              <Route
                exact
                path="/results/exceptions"
                render={(props) => (
                  <Exceptions {...props} pfxUserId={pfxUserId} />
                )}
              />
              <Route
                exact
                path="/results/crashes"
                render={(props) => <Crashes {...props} pfxUserId={pfxUserId} />}
              />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }

  // Preprocess memberDetails.address
  getAddress(address) {
    if (address == null) {
      return "";
    } else if (typeof address === "string") {
      return address;
    } else if (typeof address === "object") {
      let result = [];
      if (address.addressLine1) result.push(address.addressLine1);
      if (address.addressLine2) result.push(address.addressLine2);
      if (address.city) result.push(address.city);
      if (address.state) result.push(address.state);
      if (address.zipCode) result.push(address.zipCode);
      if (address.country) result.push(address.country);
      return result.join(", ");
    } else {
      return "";
    }
  }
}

export default Results;
