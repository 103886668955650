import React, {Component} from 'react';
import MUIDataTable from "mui-datatables";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getTokenSilently } from "../react-auth0-wrapper";

class Events extends Component {
    constructor(props) {
        super(props);

        this.state = {
            analyticsData: null
        };
    }

    async componentDidMount() {
        if (!this.props.pfxUserId) {
            return;
        }
        try {
            const token = await getTokenSilently();
      
            const response = await fetch(`/api/events?pfxUserId=${this.props.pfxUserId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                  }
            });

            const json = await response.json();
    
            const columns = json.data.tables[0].columns.map(column => {
                return column.name;
            });

            const data = json.data.tables[0].rows;
           
            this.setState({ analyticsData: { columns, data }});
      
          } catch (error) {
            console.error(error);
          }
    }
    
    render(){
        const options = {
            selectableRows: "none",
            print: false
        };

        return (
            <div className="analytics" >
                <div>
                {
                    !this.state.analyticsData &&
                    <div style={{textAlign: 'center'}}><CircularProgress size={80} /></div>
                }
                {
                    this.state.analyticsData &&
                    <MUIDataTable
                        title={`Events for user ${this.props.pfxUserId} `}
                        data={this.state.analyticsData.data}
                        columns={this.state.analyticsData.columns}
                        options={options}
                        />
                }
                </div>
            </div>
        );
    }
};

export default Events;