import React, { Component } from "react";
import Modal, { closeStyle } from "simple-react-modal";
import { getTokenSilently } from "../react-auth0-wrapper";
import { Redirect } from "react-router";
import MembershipCell from "./MembershipCell";

class Membership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barcodeVal: "",
      postalVal: "",
      redirect: false,
      showError: false,
      errorText: "",
    };
  }

  linkShow() {
    this.setState({ linkShow: true });
  }

  linkClose() {
    this.setState({ linkShow: false });
  }

  migrateClose() {
    this.setState({ migrateShow: false });
  }

  handleBarcodeInputChange = (event) => {
    this.setState({ barcodeVal: event.target.value });
  };

  handlePostalInputChange = (event) => {
    this.setState({ postalVal: event.target.value });
  };

  unlinkShow() {
    this.setState({ unlinkShow: true });
  }

  unlinkClose() {
    this.setState({ unlinkShow: false });
  }

  onNewLink = async () => {
    const token = await getTokenSilently();

    const response = await fetch(
      `https://membership.${process.env.REACT_APP_AUTH0_API}/admin/memberships/link`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          pfxUserId: this.props.pfxUserId,
          abcBarcode: this.state.barcodeVal,
          postalCode: this.state.postalVal,
        }),
      }
    );
    console.log(response);

    if (response.status >= 300) {
      const res = await response.json();
      this.setState({ showError: true });
      this.setState({ errorText: res.errors[0].message });
      return;
    }

    this.setState({ linkShow: false });
    window.location.reload();
  };

  onUnlink = async () => {
    const token = await getTokenSilently();

    const response = await fetch(
      `https://membership.${process.env.REACT_APP_AUTH0_API}/admin/memberships?pfxUserId=${this.props.pfxUserId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);

    //const res = await response.json();
    this.setState({ unlinkShow: false });
    window.location.reload();
  };

  render() {
    return (
      <div className="membershipDiv">
        <div className="membershipButtons">
          <div>
            <button
              className="linkMembership"
              onClick={this.linkShow.bind(this)}
            >
              Add Linked Membership
            </button>
            <button className="unlinkAll" onClick={this.unlinkShow.bind(this)}>
              Unlink All
            </button>
          </div>
          <div className="membershipsScroll">
            {this.props.memberships.map((membership) => (
              <MembershipCell
                key={membership.abcBarcode}
                pfxUserId={this.props.pfxUserId}
                pfxClubId={membership.abcClubNumber}
                planCat={membership.planCategory}
                abcId={membership.abcMemberId}
                abcClubNum={membership.abcClubNumber}
                abcBarcode={membership.abcBarcode}
                isActive={membership.isActive}
                sponsorType={membership.sponsorType}
              ></MembershipCell>
            ))}
          </div>
        </div>

        <Modal
          containerStyle={{ width: "550px", borderRadius: "4px" }}
          show={this.state.linkShow}
        >
          <div className="modalContainer">
            <div>Add Linked Membership</div>
            <br />
            <div className="inputs">
              <div className="abcBarcode">
                <div>ABC Barcode: </div>
                <input
                  onChange={(event) => this.handleBarcodeInputChange(event)}
                />
              </div>
              <br />
              <div className="postalCode">
                <div>Member Postal Code: </div>
                <input
                  onChange={(event) => this.handlePostalInputChange(event)}
                />
              </div>
            </div>
            {this.state.showError && (
              <div>
                <label className="errorText">{this.state.errorText}</label>
              </div>
            )}
            <br />
            <div className="modalButtons">
              <button
                className="modalSubmit"
                onClick={this.onNewLink}
                type="submit"
              >
                Submit
              </button>
              <button onClick={this.linkClose.bind(this)}>Cancel</button>
            </div>
          </div>
        </Modal>

        <Modal
          containerStyle={{ width: "550px", borderRadius: "4px" }}
          show={this.state.unlinkShow}
        >
          <div className="modalContainer">
            <div>Unlink All</div>
            <br />
            <div>Are you sure you want to unlink all memberships?</div>
            <br />
            <div className="modalButtons">
              <button
                className="modalSubmit"
                onClick={this.onUnlink}
                type="submit"
              >
                Continue
              </button>
              <button onClick={this.unlinkClose.bind(this)}>Cancel</button>
            </div>
          </div>
        </Modal>

        <Modal
          containerStyle={{ width: "550px", borderRadius: "4px" }}
          show={this.state.migrateShow}
        >
          <div className="modalContainer">
            <div>Migration Successful</div>
            <br />
            <div>The user was successfully migrated </div>
            <br />
            <div className="modalButtons">
              <button
                className="modalSubmit"
                onClick={this.migrateClose.bind(this)}
                type="submit"
              >
                Continue
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Membership;
