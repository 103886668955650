import React, { Component } from "react";
import { getTokenSilently } from "../react-auth0-wrapper";
import { FaTintSlash } from "react-icons/fa";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height,
      weight: props.weight,
      heightVal: props.heightVal,
      weightVal: props.weightVal,
      heightUnit: props.heightUnit,
      weightUnit: props.weightUnit,
      fitnessGoal: props.fitnessGoal,
      expLevel: props.expLevel,
      showError: false,
      errorText: "",
      initialSet: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && !this.state.initialSet)
      this.setState({
        height: this.props.height,
        weight: this.props.weight,
        heightVal: this.props.heightVal,
        weightVal: this.props.weightVal,
        heightUnit: this.props.heightUnit,
        weightUnit: this.props.weightUnit,
        fitnessGoal: this.props.fitnessGoal,
        expLevel: this.props.expLevel,
        initialSet: true,
      });
  }

  _onChange(value) {
    console.log(value);
    this.setState({ brandSelect: value });
  }

  onUpdateProfile = async () => {
    const token = await getTokenSilently();

    var h =
      this.state.height != null
        ? this.state.height
        : { value: 0, unit: "unit" };
    h.value = this.state.heightVal;
    h.unit = this.state.heightUnit != null ? this.state.heightUnit : "INCHES";
    var w =
      this.state.weight != null
        ? this.state.weight
        : { value: 0, unit: "unit" };
    w.value = this.state.weightVal;
    w.unit = this.state.weightUnit != null ? this.state.weightUnit : "POUNDS";

    const response = await fetch(
      `https://activities.${process.env.REACT_APP_AUTH0_API}/admin/profile/${this.props.pfxUserId}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          height: h,
          weight: w,
          fitnessGoal: this.state.fitnessGoal,
          experienceLevel: this.state.expLevel,
        }),
      }
    );
    console.log(response);

    if (response.status >= 300) {
      const res = await response.json();
      this.setState({ showError: true });
      this.setState({ errorText: res.errors[0].message });
      return;
    }
    window.location.reload(false);

    //this.setState({ linkShow: false });
    //window.location.reload();
  };

  render() {
    console.log(this.props.fitnessGoal);
    console.log(this.state.fitnessGoal);
    return (
      <div className="profile">
        <div className="flexProfile">
          <p>Height</p>
          <div className="marginProfile">
            <input
              className="input"
              placeholder={this.props.heightVal}
              onChange={(e) => this.setState({ heightVal: e.target.value })}
            />
            <select
              value={this.state.heightUnit}
              onChange={(e) => this.setState({ heightUnit: e.target.value })}
            >
              <option value="INCHES">Inches</option>
              <option value="CENTIMETERS">Centimeters</option>
            </select>
          </div>
        </div>
        <div className="flexProfile">
          <p>Weight</p>
          <div className="marginProfile">
            <input
              className="input"
              placeholder={this.props.weightVal}
              onChange={(e) => this.setState({ weightVal: e.target.value })}
            />
            <select
              value={this.state.weightUnit}
              onChange={(e) => this.setState({ weightUnit: e.target.value })}
            >
              <option value="POUNDS">Pounds</option>
              <option value="KILOGRAMS">Kilograms</option>
            </select>
          </div>
        </div>
        <div className="flexProfile">
          <p>Fitness Goal</p>
          <select
            value={this.state.fitnessGoal}
            onChange={(e) => this.setState({ fitnessGoal: e.target.value })}
          >
            <option value="STAY_ACTIVE" key="STAY_ACTIVE">
              Stay Active
            </option>
            <option value="LOSE_WEIGHT" key="LOSE_WEIGHT">
              Lose Weight
            </option>
            <option key="BUILD_MUSCLE" value="BUILD_MUSCLE">
              Build Muscle
            </option>
            <option value="BOOST_ENDURANCE" key="BOOST_ENDURANCE">
              Boost Endurance
            </option>
            <option value="IMPROVE_FLEXIBILITY" key="IMPROVE_FLEXIBILITY">
              Improve Flexibility
            </option>
          </select>
        </div>
        <div className="flexProfile">
          <p>Experience Level</p>
          <select
            value={this.state.expLevel}
            onChange={(e) => this.setState({ expLevel: e.target.value })}
          >
            <option value="BEGINNER" key="BEGINNER">
              Beginner
            </option>
            <option value="INTERMEDIATE" key="INTERMEDIATE">
              Intermediate
            </option>
            <option value="ADVANCED" key="ADVANCED">
              {" "}
              Advanced
            </option>
          </select>
        </div>

        <div className="profileButtons">
          <button className="update" onClick={this.onUpdateProfile}>
            Update
          </button>
          {/* <button className="cancel">Cancel</button> */}
        </div>
      </div>
    );
  }
}

export default Profile;
