import React, { Component } from "react";
import { getTokenSilently } from "../react-auth0-wrapper";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.pfxUserId,
      gender: props.gender,
      email: props.email,
      phone: props.phone,
      givenName: props.givenName,
      address: props.address,
      familyName: props.familyName,
      birthDate: props.birthDate,
      showError: false,
      errorText: "",
      initialSet: false,
    };
  }

  propsChanged(prevProps, newProps) {
    return (
      prevProps.pfxUserId !== newProps.pfxUserId ||
      prevProps.gender !== newProps.gender ||
      prevProps.email !== newProps.email ||
      prevProps.phone !== newProps.phone ||
      prevProps.givenName !== newProps.givenName ||
      prevProps.address !== newProps.address ||
      prevProps.familyName !== newProps.familyName ||
      prevProps.birthDate !== newProps.birthDate
    );
  }

  componentDidUpdate(prevProps) {
    if (this.propsChanged(prevProps, this.props) && !this.state.initialSet)
      this.setState({
        userId: this.props.pfxUserId,
        gender: this.props.gender,
        email: this.props.email,
        phone: this.props.phone,
        givenName: this.props.givenName,
        address: this.props.address,
        familyName: this.props.familyName,
        birthDate: this.props.birthDate,
        initialSet: true,
      });
  }

  onUpdateUser = async () => {
    const token = await getTokenSilently();

    const response = await fetch(
      `https://member-services.${process.env.REACT_APP_AUTH0_API}/admin/account/${this.props.pfxUserId}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: this.state.email,
          givenName: this.state.givenName,
          familyName: this.state.familyName,
        }),
      }
    );

    if (response.status >= 300) {
      const res = await response.json();
      this.setState({ showError: true });
      this.setState({ errorText: res.errors[0].message });
      return;
    }

    window.location.reload(false);
  };

  render() {
    return (
      <div className="user">
        <div>
          <div className="userInfo">
            <p>PFX User ID</p>
            <p className="info">{this.props.pfxUserId}</p>
          </div>
          <div className="userInfo">
            <p>Gender</p>
            <p className="info">{this.props.gender}</p>
          </div>
        </div>

        <div>
          <div className="userInfo">
            <p>Email</p>
            <div>
              <input
                className="input"
                placeholder={this.props.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
          </div>
          <div className="userInfo">
            <p>Phone</p>
            <p className="info">{this.props.phone}</p>
          </div>
        </div>

        <div>
          <div className="userInfo">
            <p>Given Name</p>
            <div>
              <input
                className="input"
                placeholder={this.props.givenName}
                onChange={(e) => this.setState({ givenName: e.target.value })}
              />
            </div>
          </div>
          <div className="userInfo">
            <p>Address</p>
            <p className="info">{this.props.address}</p>
          </div>
        </div>

        <div>
          <div className="userInfo">
            <p>Family Name</p>
            <div>
              <input
                className="input"
                placeholder={this.props.familyName}
                onChange={(e) => this.setState({ familyName: e.target.value })}
              />
            </div>
          </div>
          <div className="userInfo">
            <p>Birth Date</p>
            <p className="info">{this.props.birthDate}</p>
          </div>
        </div>

        <div>
          <div className="userInfo profileButtons">
            <button className="update" onClick={this.onUpdateUser}>
              Update
            </button>
            {/* <button className="cancel">Cancel</button> */}
          </div>
        </div>
      </div>
    );
  }
}
export default User;
