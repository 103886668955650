import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import AuthProfile from "./Components/AuthProfile";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-wrapper";
import { PrivateRoute } from "./Components/PrivateRoute";
import MainApp from "./MainApp";

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const routing = (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirect_uri={`${process.env.REACT_APP_AUTH0_BASE_URL}/auth0-delegated-admin/login`}
    audience={`${process.env.REACT_APP_AUTH0_AUDIENCE}`}
    returnTo={`${process.env.REACT_APP_AUTH0_BASE_URL}`}
    scope="hello:read&class-attendance:write&classes:read&clubs:read&clubs:write&fitness-profile:read&fitness-profile:write&memberships:all:read&memberships:individual:write memberships:corporate:write activation-codes:write sponsors:write&sponsors:read&agreements:read&reports:read&user-profile:read user-profile:write offers:read offers:write member-support"
    onRedirectCallback={onRedirectCallback}
  >
    <MainApp />
  </Auth0Provider>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
