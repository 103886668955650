import React, { Component } from "react";
import Modal, { closeStyle } from "simple-react-modal";
import { getTokenSilently } from "../react-auth0-wrapper";
import { Redirect } from "react-router";
import DigitalMembershipCell from "./DigitalMembershipCell";

class DigitalMembership extends Component {
  debugger;
  constructor(props) {
    super(props);
    this.state = {
      barcodeVal: "",
      postalVal: "",
      redirect: false,
      showError: false,
      errorText: "",
    };
  }

  render() {
    return (
      <div className="membershipDiv">
        <div className="membershipButtons">
          <div className="membershipsScroll">
            {this.props.digitalMemberships.map((membership) => (
              <DigitalMembershipCell
                membership={membership}
              ></DigitalMembershipCell>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default DigitalMembership;
